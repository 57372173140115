@import '@angular/material/theming';

@mixin clone-loading-indicator-component-theme($theme) {
	$custom: $custom-colors-theme-default;
	app-loading-indicator {
		.loading-bar {
			background: linear-gradient(
				to right,
				mat-color($custom, loading-indicator-color-from),
				mat-color($custom, loading-indicator-color-to),
				mat-color($custom, loading-indicator-color-from),
				mat-color($custom, loading-indicator-color-to),
				mat-color($custom, loading-indicator-color-from),
				mat-color($custom, loading-indicator-color-to),
				mat-color($custom, loading-indicator-color-from)
			);
			background-size: 300% 300%;
		}
	}
}
