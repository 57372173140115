@import '@angular/material/theming';

@mixin mat-button-component-theme-m2($theme) {
	$primary: $mat-palette-primary;
	$warn: $mat-palette-warn;
	$success: $mat-palette-success;
	$foreground: $foreground-theme-default;
	$custom: $custom-colors-theme-default;
	$shades: $mat-palette-shades;

	.mat-button {
		background: transparent;
		// --mat-text-button-pressed-state-layer-opacity: 0 !important;
		&.mat {
			&-primary {
				color: mat-color($primary, A100);
				background-color: mat-color($primary, 400);
				&:hover {
					background-color: mat-color($primary, 500);
				}
				&:active {
					background-color: mat-color($primary, 300);
				}
			}
			&-warn {
				color: mat-color($shades, A100);
				background-color: mat-color($warn, 700);
				&:hover {
					background-color: mat-color($warn);
				}
			}
			&-neutral {
				color: mat-color($foreground, text);
				&:hover {
					background-color: mat-color($shades, A400);
				}
				&.outline:not(:disabled):not(.disabled) {
					background-color: mat-color($primary, A100);
					box-sizing: border-box;
					box-shadow: inset 0px 0px 0px 1px mat-color($shades, 300);
					&:hover {
						box-shadow: inset 0px 0px 0px 1px mat-color($primary, 500) !important;
						color: mat-color($primary, 500);
					}
					&:active {
						box-shadow: inset 0px 0px 0px 1px mat-color($primary, 300) !important;
						color: mat-color($primary, 300);
					}
					&:disabled {
						box-shadow: inset 0px 0px 0px 1px mat-color($shades, 200);
						background-color: mat-color($primary, A100);
					}
				}
				&.outline:disabled {
					box-shadow: inset 0px 0px 0px 1px mat-color($shades, 50);
					color: mat-color($shades, 50) !important;
					background: transparent !important;
				}
			}
			&-ghost {
				color: mat-color($foreground, text);
				background-color: mat-color($primary, A100);

				&:hover {
					color: mat-color($primary, 500);
					background-color: mat-color($primary, A100);
				}
				&:active {
					color: mat-color($primary, 300);
					background-color: mat-color($primary, A100);
				}
				&:disabled {
					color: mat-color($shades, 50) !important;
					background-color: mat-color($primary, A100) !important;
				}
			}
		}
		&:disabled,
		&.disabled,
		&[disabled] {
			background-color: mat-color($shades, A700) !important;
			color: mat-color($shades, A100) !important;
		}
		&:hover {
			background: mat-color($shades, A400);
		}
		&:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
			opacity: 0 !important;
		}
	}
	.mat-icon-button {
		&.mat-primary:not(.mat-button-disabled):hover {
			color: mat-color($primary, 700);
		}
		&.mat-warn:not(.mat-button-disabled):hover {
			color: mat-color($warn, 700);
		}
		&:not(.mat-button-disabled):hover {
			color: mat-color($primary, 500);
		}
	}
	.mat-button-toggle-checked {
		&.mat-button-toggle-appearance-standard {
			color: mat-color($primary, A100);
		}
	}
	.mat-menu-item[aria-disabled='true'],
	.mat-icon-button[disabled='true'] {
		opacity: 0.5;
		cursor: not-allowed;
	}

	.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
	.mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
		opacity: 0;
	}

	.mat-mdc-outlined-button:hover {
		.mat-icon {
			color: mat-color($primary, 500);
		}

		.mdc-button__label {
			color: mat-color($primary, 500);
		}
	}

	.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
		display: none;
	}

	.mat-mdc-icon-button .mat-mdc-button-ripple {
		display: none;
	}
	.mat-mdc-icon-button {
		--mdc-icon-button-icon-size: 20px !important;
		&.mat-primary:not(.mat-button-disabled):hover {
			color: mat-color($primary, 700);
		}
		&.mat-warn:not(.mat-button-disabled):hover {
			color: mat-color($warn, 700);
		}
		&:not(.mat-button-disabled):hover {
			color: mat-color($primary, 500);
		}
		svg:not(.mat-mdc-checkbox svg) {
			--mdc-icon-button-icon-size: 100% !important;
		}
	}

	.mdc-button {
		height: $element-height !important;
		border-radius: $border-radius !important;
		&.mat {
			&-primary {
				color: mat-color($primary, A100) !important;
				background-color: mat-color($primary, 400);
				&:hover {
					background-color: mat-color($primary, 500);
				}
				&:active {
					background-color: mat-color($primary, 300);
				}
				&:disabled,
				&.disabled,
				&[disabled] {
					background-color: mat-color($shades, A700) !important;
					color: mat-color($shades, A100) !important;
				}
			}
			&-ghost:not(.none-pl) {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
			&-ghost {
				color: mat-color($foreground, text);
				background-color: mat-color($primary, A100);

				&:hover {
					color: mat-color($primary, 500);
					background-color: mat-color($primary, A100);
				}
				&:active {
					color: mat-color($primary, 300);
					background-color: mat-color($primary, A100);
				}
				&:disabled {
					color: mat-color($shades, 50) !important;
					background-color: mat-color($primary, A100) !important;
				}
			}
			&-neutral {
				color: mat-color($foreground, text);
				&.outline:not(:disabled):not(.disabled) {
					background-color: mat-color($primary, A100) !important;
					box-sizing: border-box;
					box-shadow: inset 0px 0px 0px 1px mat-color($shades, 300);
					&:hover {
						box-shadow: inset 0px 0px 0px 1px mat-color($primary, 500) !important;
						color: mat-color($primary, 500);

						.mat-mdc-button-persistent-ripple::before {
							opacity: 0 !important;
						}
					}
					&:active {
						box-shadow: inset 0px 0px 0px 1px mat-color($primary, 300) !important;
						color: mat-color($primary, 300);
					}
					&:disabled {
						box-shadow: inset 0px 0px 0px 1px mat-color($shades, 200);
						background-color: mat-color($primary, A100);
					}
				}
				&.outline:disabled {
					// box-shadow: inset 0px 0px 0px 1px mat-color($shades, 50);
					color: mat-color($shades, 50) !important;
					background: transparent !important;
				}
			}
		}
	}

	.mdc-button.is-small:not(.not-pl) {
		height: $sm-height !important;
		// for old styles
		padding: 5px 15px 4px !important;
		font-size: 12px !important;
	}

	.mdc-fab--extended {
		height: $element-height !important;
		padding-left: 14px !important;
		padding-right: 14px !important;
		border-radius: $border-radius !important;
	}

	.mat-mdc-extended-fab > .mat-icon,
	.mat-mdc-extended-fab > .material-icons {
		height: 20px;
		width: 24px;
		margin-right: 8px !important;
	}

	.mat-mdc-unelevated-button:disabled {
		color: white !important;
	}

	.mat-mdc-unelevated-button {
		padding: 10px 30px 9px 30px !important;
	}

	.mat-mdc-button-touch-target {
		display: none;
	}

	.mat-mdc-icon-button.mat-mdc-button-base {
		width: auto;
		height: auto;
		// --mdc-icon-button-state-layer-size: auto;
	}

	.mat-mdc-button {
		&.cancel-button,
		&.discard-btn {
			min-width: auto !important;
			padding: 0 !important;

			// .mat-mdc-focus-indicator,
			// .mat-mdc-button-touch-target,
			// .mat-mdc-button-ripple,
			// .mdc-button__ripple {
			// 	display: none;
			// }
		}

		.mat-mdc-focus-indicator,
		.mat-mdc-button-touch-target,
		.mat-mdc-button-ripple,
		.mdc-button__ripple {
			display: none;
		}

		.mat-mdc-button-persistent-ripple {
			display: none;
		}

		&.none-pl {
			padding: 0px;
			min-width: auto !important;
		}
	}

	.mat-mdc-outlined-button {
		.mat-mdc-focus-indicator,
		.mat-mdc-button-touch-target,
		.mat-mdc-button-ripple,
		.mdc-button__ripple {
			display: none;
		}
	}

	app-ocm-search-field , app-active-directory{
		.mat-mdc-icon-button {
			&.is-small {
				width: 16px !important;
				height: 16px !important;
				line-height: 16px !important;
				font-size: 16px !important;
				padding: 0px !important;
				margin: 0px 10px 5px 0px !important;
				.mat-icon {
					@extend .is-small;
				}
			}
		}
	}

	app-delete-user-dialog {
		.mat-mdc-icon-button.mat-mdc-button-base {
			--mdc-icon-button-state-layer-size: 20px !important;
			padding: 0px;
		}
	}
}
