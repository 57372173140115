@import '@angular/material/theming';

@mixin mdc-button-component-theme($theme) {
	$primary: $mat-palette-primary;
	$warn: $mat-palette-warn;
	$success: $mat-palette-success;
	$shades: $mat-palette-shades;
	$background: $background-theme-default;
	$foreground: $foreground-theme-default;
	.mdc-button {
		background: transparent;
		&.mat {
			&-primary {
				color: mat-color($primary, A100);
				background-color: mat-color($primary, 400);
				&:hover {
					background-color: mat-color($primary, 500);
				}
				&:active {
					background-color: mat-color($primary, 300);
				}
			}
			&-warn {
				color: mat-color($shades, A100);
				background-color: mat-color($warn, 700);
				&:hover {
					background-color: mat-color($warn);
				}
			}
			&-neutral {
				color: mat-color($foreground, text);
				&:hover {
					background-color: mat-color($shades, A400);
				}
				&.outline:not(:disabled):not(.disabled) {
					background-color: mat-color($primary, A100) !important;
					box-sizing: border-box;
					box-shadow: inset 0px 0px 0px 1px mat-color($shades, 300);
					&:hover {
						box-shadow: inset 0px 0px 0px 1px mat-color($primary, 500) !important;
						color: mat-color($primary, 500);

						.mat-mdc-button-persistent-ripple::before {
							opacity: 0 !important;
						}
					}
					&:active {
						box-shadow: inset 0px 0px 0px 1px mat-color($primary, 300) !important;
						color: mat-color($primary, 300);
					}
					&:disabled {
						box-shadow: inset 0px 0px 0px 1px mat-color($shades, 200);
						background-color: mat-color($primary, A100);
					}
				}
				&.outline:disabled {
					box-shadow: inset 0px 0px 0px 1px mat-color($shades, 50);
					color: mat-color($shades, 50) !important;
					background: transparent !important;
				}
			}
			&-ghost {
				color: mat-color($foreground, text);
				background-color: mat-color($primary, A100);

				&:hover {
					color: mat-color($primary, 500);
					background-color: mat-color($primary, A100);
				}
				&:active {
					color: mat-color($primary, 300);
					background-color: mat-color($primary, A100);
				}
				&:disabled {
					color: mat-color($shades, 50) !important;
					background-color: mat-color($primary, A100) !important;
				}
			}
		}
		&:disabled,
		&.disabled,
		&[disabled] {
			background-color: mat-color($shades, A700) !important;
			color: mat-color($shades, A100) !important;
		}
		&:hover {
			background: mat-color($shades, A400);
		}
		&:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
			opacity: 0 !important;
		}
	}
	.mat-icon-button {
		&.mat-primary:not(.mat-button-disabled):hover {
			color: mat-color($primary, 700);
		}
		&.mat-warn:not(.mat-button-disabled):hover {
			color: mat-color($warn, 700);
		}
		&:not(.mat-button-disabled):hover {
			color: mat-color($primary, 500);
		}
	}
	.mat-button-toggle-checked {
		&.mat-button-toggle-appearance-standard {
			color: mat-color($primary, A100);
		}
	}
	.mat-menu-item[aria-disabled='true'],
	.mat-icon-button[disabled='true'] {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
