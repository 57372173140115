@import '@angular/material/theming';

@mixin app-metadefender-storage-security-instance($theme) {
	$warn: $mat-palette-warn;
	$shades: $mat-palette-shades;
	$primary: $mat-palette-primary;

	app-metadefender-storage-security-instance {
		.warning {
			color: $tertiary-highlight;
		}

		.danger {
			color: mat-color($warn, 700);
		}

		.mat-button.rename-instance-selected {
			color: mat-color($primary, 500);
		}
	}
}
