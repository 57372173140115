@import '@angular/material/theming';

@mixin mat-tab-component-theme-m2($theme) {
	$primary: $mat-palette-primary;
	$foreground: $foreground-theme-default;
	$warn: $mat-palette-warn;
	$shades: $mat-palette-shades;

	.mat-mdc-tab-nav-bar,
	.mat-mdc-tab-group {
		&.mat-primary {
			.mat-mdc-tab-link,
			.mat-mdc-tab-label {
				cursor: pointer;
				font-weight: normal;
				color: mat-color($shades, 300) !important;
				border-bottom: 2px solid mat-color($shades, 50);
				// &.mat-mdc-tab-label-active {
				// 	color: mat-color($shades, 500) !important;
				// 	font-weight: 500;
				// }

				&.mdc-tab--active {
					.mdc-tab__text-label {
						color: mat-color($shades, 400) !important;
						font-weight: 500;
					}

					border-bottom: 2px solid mat-color($primary, 400) !important;
					// color: mat-color($primary, 500) !important;
				}

				&.invalid {
					color: mat-color($warn);
				}
				&.mat-mdc-tab-disabled:not(.mat-mdc-tab-label-active) {
					color: mat-color($shades, A700) !important;
					cursor: default;
				}
				&:hover:not(.mat-mdc-tab-disabled) {
					border-color: mat-color($shades, A400);
					color: mat-color($shades, 500) !important;
					// background-color: transparent;
					text-decoration: none;
					font-weight: 500;
				}
			}

			.mat-mdc-tab-link:hover .mdc-tab__ripple::before {
				color: mat-color($shades, 500) !important;
				background-color: transparent !important;
				// opacity: 1;
			}

			.mdc-tab-indicator--active .mdc-tab-indicator__content {
				opacity: 0 !important;
			}

			.mat-ink-bar {
				background-color: mat-color($primary, 400);
			}
			.mat-mdc-tab-link:hover .mdc-tab__text-label {
				color: mat-color($shades, 500) !important;
			}
		}
		.mat-ink-bar {
			transition: none !important;
			transition-duration: 0ms !important;
			transition-delay: 0ms !important;
		}
	}

	.mat-mdc-tab-nav-bar {
		.mat-mdc-tab-link {
			opacity: 1;
			font-size: 15px;
			font-weight: 500;
			min-width: fit-content;

			&:focus:not(.mat-mdc-tab-disabled) {
				background-color: transparent !important;
			}
		}
	}
	.mat-mdc-tab-nav-bar.mat-mdc-tab-header,
	.mat-mdc-tab-group .mat-mdc-tab-header {
		border-bottom: none;
	}

	.mat-mdc-tab-links,
	.mat-mdc-tab-labels {
		position: relative;

		&::before {
			position: absolute;
			content: '';
			height: 2px;
			width: 100%;
			bottom: 0;
			right: 0;
			background-color: mat-color($shades, A400) !important;
		}
	}

	.mat-mdc-tab-link.mdc-tab--active .mdc-tab__ripple::before {
		background-color: mat-color($shades, A400) !important;
	}

	.mat-mdc-tab-link.cdk-program-focused .mdc-tab__ripple::before {
		background-color: mat-color($shades, A400) !important;
	}

	.mat-mdc-tab-links .mat-mdc-tab-link {
		opacity: 1 !important;
		position: relative;
		margin-right: 2px;
		height: 32px !important;
	}

	.mat-mdc-tab-body.mat-mdc-tab-body-active {
		overflow-y: visible;
	}
	.mat-mdc-tab-body-wrapper {
		overflow: visible !important;
	}
}
