@import '@angular/material/theming';

@mixin mat-table-component-theme-m2($theme) {
	$primary: $mat-palette-primary;

	table {
		th:first-child,
		td:first-child {
			padding-left: 0;
		}

		&.mat-mdc-table thead .mat-mdc-header-cell,
		&.mat-mdc-table thead th {
			color: #3d4a68 !important;
			border-bottom-color: #d3d9e6;
			font-size: 12px !important;
		}
		.mat-sort-header-arrow {
			color: #8b9dc1;
			opacity: 0.54 !important;
			transform: translateY(0px) !important;
		}

		.mat-sort-header-sorted .mat-sort-header-arrow {
			color: mat-color($primary, 400);
			opacity: 1 !important;
		}

		.mdc-data-table__cell, .mdc-data-table__header-cell {
			padding: 5px 5px 5px 0px;
		}
	}

	.mdc-data-table__row:last-child .mat-mdc-cell {
		border-bottom: 1px solid var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) !important;
	}

	app-product-group-detail-instance,
	app-product-group,
	app-product-instance {
		.mat-mdc-row {
			--mat-table-row-item-label-text-color: $primary !important
		}
	}
}