// color missing in palate
$bg-primary-950-no-palate: #081938;
$bg-neutrals-950: #777d88;

// color using in dashboard
$color-no-instance: #777d88;
$color-total-instance: #1b273c;
$color-pending-instance: #13b213;
$color-larger-than-120: #e9eaeb;
$color-smaller-than-120: #fdaf0d;
$color-smaller-than-90: #fd760d;
$color-smaller-than-30: #df6200;
$color-expired: #d00300;
$color-bg-no-report: #8e939b;

$border-radius: 0px;
$sm-height: 30px;
$md-height: 40px; // Current height is 40px, should be 44px.
$lg-height: 48px;

$element-height: $md-height;
