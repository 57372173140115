/* Works on Firefox */
@supports not selector(::-webkit-scrollbar) {
	*:not(mat-sidenav-content) {
		scrollbar-width: thin;
	}
}

/* Works on Chrome, Edge, and Safari */
*:not(mat-sidenav-content)::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

*:not(mat-sidenav-content)::-webkit-scrollbar-thumb {
	border-radius: 20px;
	border-width: 1px;
	border-style: solid;
}

.beta-white-watermark {
	background-image: url(../assets/images/beta-watermark.png);
	background-repeat: no-repeat;
	background-size: auto;
	min-height: calc(100vh - 250px);
}

.table-wrapper {
	width: 100%;
	overflow-x: auto;
}

// TODO move to opswat-ui
.form-disabled {
	cursor: default !important;
	pointer-events: none !important;
}

mat-sidenav-container mat-sidenav-content main .container {
	grid-row-gap: 0;
	margin-bottom: 90px;
}

.mat-ghost.cdk-program-focused,
.mat-ghost.cdk-focused {

	// color: $primary-blue !important;
	.mat-button-focus-overlay {
		background-color: transparent !important;
	}
}

.text-center {
	text-align: center;
}

.text-medium {
	font-weight: 500 !important;
}

.text-normal {
	font-weight: 400 !important;
}

.text-italic {
	font-style: italic;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-uppercase {
	text-transform: uppercase;
}

.content-body {
	grid-column: 1/13;
	grid-row: 2;
	width: 100%; // For Firefox: prevent shrink/grow element's width in nested grid layout
}

.max-w-450 {
	max-width: 450px;
}

ul.doc-link {
	padding-left: 0;

	li {
		display: block;
	}

	a {
		text-decoration: underline;
		color: mat-color($palette-opswat-shades, 500);
	}
}

// for on-demand feedback
.feedback-title {
	font-size: 25px !important;
	max-width: 320px;
	word-wrap: break-word;
}

//
// Dialog styles
// DO not adding extra block to the dialog layout
// DO not add mat-dialog-actions to footer
.dialog-small {
	&__header {
		height: 40px !important;
		margin-bottom: 0 !important;
	}

	&__content {
		height: 140px !important;
		overflow: auto;
	}

	&__footer {
		height: 72px !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}

.dialog-medium {
	&__header {
		height: 40px !important;
		margin-bottom: 0 !important;
	}

	&__content {
		height: 320px !important;
		overflow: auto;
	}

	&__footer {
		height: 72px !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.pin-setting {
			margin-top: 5px;
		}

		.input {
			padding-bottom: 3px;
			padding-left: 10px;
		}
	}
}

.dialog-large {
	&__header {
		height: 40px !important;
		margin-bottom: 0 !important;
	}

	&__content {
		min-height: 320px !important;
		overflow: auto;
	}

	&__footer {
		height: 72px !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.pin-setting {
			margin-top: 5px;
		}

		.input {
			padding-bottom: 3px;
			padding-left: 10px;
		}
	}
}

.mat-mdc-dialog-container {
	.dialog-large {
		&__content {
			min-height: 320px !important;
			overflow: auto;
		}
	}
}

.dialog-extra-large {
	&__header {
		height: 40px !important;
		margin-bottom: 0 !important;
	}

	&__content {
		height: 1200px !important;
		overflow: auto;
	}

	&__footer {
		height: 72px !important;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}

.dialog-status-panel {
	position: absolute;
	top: 0;
	left: 0;
	transform: translateY(-100%);
	z-index: 1;
	height: 40px;
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	color: $primary-white;

	&.is-failed {
		background-color: mat-color($palette-opswat-warn, 700);
	}

	&.is-success {
		background-color: mat-color($palette-opswat-success, 700);
	}
}

.dialog-medium__content--custom-height {
	height: 380px !important;
	max-height: 380px;
}

.overlay-dialog-status-panel .mat-dialog-container {
	position: relative;
	overflow: initial;
}

app-create-confirmation-dialog,
app-adding-instance-dialog,
app-moving-to-another-group-dialog,
app-result-confirmation,
app-partial-result-dialog {
	.instances-table {
		border-spacing: 0;

		thead th {
			text-align: left;
			padding-left: 9px;
			padding-bottom: 3px;
		}

		tbody tr {
			td {
				padding: 5px 9px;
			}
		}
	}

	.title-highlight {
		font-size: 16px;
	}
}

app-add-new-group,
app-adding-new-group {
	.mat-button .mat-icon {
		transform: none !important;
		margin-right: 0px !important;
	}

	.hover-icon {
		display: none;
	}

	.version:hover .hover-icon {
		display: block;
	}
}

.action-separator {
	width: 1px;
	height: 20px;
	background-color: #b7c3dc;
	opacity: 0.5;
}

.small-height {
	&.mat-form-field-appearance-outline {
		.mat-form-field-infix {
			padding: 5px 0 !important;
		}
	}
}

.mat-option[aria-disabled='true'] {
	pointer-events: none;
}

.align-sub {
	vertical-align: sub !important;
}

.disabled-content {
	pointer-events: none;
	opacity: 0.4;
	cursor: pointer;
}

app-roles {
	mat-form-field.mat-form-field-appearance-outline .mat-form-field-flex {
		padding: 0 15px 0 0 !important;
	}
}

app-role-detail-form {
	mat-form-field.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-infix input {
		color: currentColor !important;
	}

	.mat-radio-button.mat-accent.mat-radio-checked.mat-radio-disabled .mat-radio-outer-circle {
		background-color: #2672fb;
	}
}

app-result-confirmation,
app-moving-to-another-group-dialog,
app-adding-instance-dialog,
app-application-group-configuration-dialog,
app-group-detail-apply-group-configuration,
app-action-multiple-instance-dialog,
app-adding-new-group {
	.choosing-table {
		height: 203px;
		overflow-y: auto;
		background-color: mat-color($palette-opswat-shades, A700, 30%);

		.none-background-row {
			padding: 12px 10px;
			width: 100%;
			height: 36px;
			color: $primary-dark;
		}

		.row {
			padding: 12px 10px;
			width: 100%;
			height: 36px;
			color: $primary-dark;

			&:not(:last-child) {
				margin-bottom: 7px;
			}
		}

		.active-row {
			background-color: $primary-blue !important;
			color: $primary-white;
		}
	}
}

app-kiosk-scan-history,
app-media-firewall-scan-history,
app-kiosk-group,
app-kiosk-instance,
app-media-firewall-instance,
app-media-firewall-group,
app-product-instance,
app-product-group {
	.mat-table {
		mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
			margin-top: 0 !important;
			position: relative !important;
			padding: 0 !important;
			top: 2px !important;
		}

		mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-subscript-wrapper .mat-error {
			max-width: 500px !important;
		}

		.mat-error {
			max-width: 450px !important;
		}
	}

	.min-w-450 {
		min-width: 450px;
	}
}

app-left-menu {
	[ng-reflect-svg-icon='supervisor-approval'] path {
		stroke: #3d4a68 !important;
	}
}

.title-confirmation {
	font-style: bold;
	font-size: 14px;
	padding: 5px;
	margin-right: 5px;
	margin-left: 0 !important;
}

.text-default {
	font-weight: normal !important;
}

.pin-input-with-error {
	>span {
		position: relative;
		top: 2px;
	}

	.mat-form-field {
		.mat-error {
			position: absolute;
			line-height: 15px;
		}
	}
}

.is-custom-hover:hover {
	color: mat-color($palette-opswat-primary, 500);
}

.flex-shrink-none {
	flex-shrink: 0;
}

.mat-option {
	&.mat-active {
		background: $primary-white !important;
	}
}

.mat-select-panel {
	max-width: unset !important;
}

.mat-progress-bar {
	&--determinate-color {
		.mat-progress-bar-buffer {
			background-color: $primary-dark !important;
		}

		.mat-progress-bar-fill::after {
			background: $primary-blue !important;
		}
	}
}

.d-none {
	display: none;
}

.mat-menu-panel {
	min-height: 0 !important;
}

.align-center {
	text-align: center !important;
}

.max-w-600 {
	max-width: 600px;
}

.max-w-500 {
	max-width: 500px;
}

.upload-file-progress-panel {
	mat-dialog-container {
		padding: 0;
	}
}

.text-underline {
	text-decoration: underline;
}

.min-h-50 {
	min-height: 50px;
}

textarea {
	resize: none !important;
}

.d-inline-block {
	display: inline-block;
}

.apexcharts-tooltip {
	border-radius: 0 !important;
}

.apexcharts-series-markers {
	display: none !important;
}

.flex-column-reverse {
	flex-direction: column-reverse;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

app-search-product {
	.mat-form-field-infix {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		height: 18px !important;
	}

	.mat-icon-sm {
		width: 16px !important;
		height: 16px !important;
	}

	.mat-form-field-suffix {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		top: 0px !important;
		height: 18px !important;
	}

	.mat-form-field-flex {
		display: flex !important;
		align-items: center !important;
		padding: 5px 0 5px 0 !important;
	}
}

app-active-directory {
	.search-wrap {
		.mat-form-field-infix {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			height: 18px !important;
			width: 250px !important;
		}

		.mat-icon-sm {
			width: 16px !important;
			height: 16px !important;
		}

		.mat-form-field-suffix {
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			top: -2px !important;
			height: 18px !important;
		}

		.mat-form-field-flex {
			display: flex !important;
			align-items: center !important;
			padding: 5px 0 5px 0 !important;
		}
	}

	.content--large {
		.mat-form-field-infix {
			width: 100px !important;
		}
	}
}

.h-100-p {
	height: 100%;
}

.bar-chart {
	apx-chart {
		.apexcharts-yaxis-inversed {
			line {
				visibility: hidden;
			}
		}

		.apexcharts-xaxis-inversed {
			line {
				visibility: hidden;
			}
		}
	}
}

.z-index-1 {
	z-index: 1;
}

app-kiosk-instance,
app-overview-instance,
app-kiosk-group-detail-instance .mat-column-license {
	min-width: 120px;
}

.w-full {
	width: max-content;
}

.scroll-y-hidden {
	overflow-y: hidden !important;
}

.grid {
	display: grid;
}

app-dashboard,
app-media-firewall-overview,
app-core-overview,
app-mft-overview,
app-metadefender-icap-overview {
	.justify-space {
		justify-content: space-between;
	}

	.color-white {
		color: white;
	}

	.gap-x-2 {
		row-gap: 2px;
	}

	.apex-custom-tooltip-scan-result,
	.apex-custom-tooltip-total-session {
		min-width: 200px;
		max-width: 350px;
		background-color: white;
		color: #1b273c;
		font-family: Roboto, Arial, sans-serif !important;
		font-style: normal;
		font-size: 14px;
		line-height: 206%;
		font-weight: 500;
		padding: 2px;

		.tooltip-header {
			color: #1b273c;
			margin: 4px 5px;
			align-items: center;
		}

		.tooltip-footer {
			display: flex;
			justify-content: end;
			color: #202d42;
			text-align: right;
			font-size: 11px;
			font-weight: 400;
			line-height: 16px;
			margin: 8px 5px;
			align-items: center;
		}

		.scan-status,
		.session-status {
			padding: 0 5px;
		}

		.session-sub-status {
			padding: 0 4px;
			background-color: #f9e6e5;
			column-gap: 5px;
			padding-left: 10px;

			.label {
				max-width: 300px;
				text-align: justify;
				word-break: break-word;
				white-space: pre-line;
				overflow-wrap: break-word;
				-ms-word-break: break-word;
				-ms-hyphens: auto;
				-moz-hyphens: auto;
				-webkit-hyphens: auto;
			}
		}
	}

	.apexcharts-legend-series {
		display: flex !important;
		align-items: center !important;
		margin: 5px 5px !important;
		min-width: 170px !important;
		justify-content: space-between !important;
	}

	.apexcharts-legend {
		max-width: fit-content !important;
	}

	.apexcharts-legend-text {
		width: 100% !important;
		color: #3d4a68 !important;

		.flex {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.flex>div:first-child {
			margin-right: auto;
		}

		.flex>div:last-child {
			width: 150px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.apx-legend-position-right {
		top: 0 !important;
		overflow: hidden !important;
	}

	.apexcharts-legend {
		justify-content: flex-start !important;
	}

	@media screen and (min-width: 1550px) {
		.apexcharts-legend-series {
			display: flex !important;
			align-items: center !important;
			margin: 5px 5px !important;
			min-width: 230px !important;
		}
	}

	.no-label {
		.apexcharts-xaxis {
			visibility: hidden;
		}
	}

	mat-checkbox {
		&.mat-checkbox-checked {
			&.area-chart-legend-checkbox-Blocked {
				.mat-checkbox-background {
					background-color: #d00300 !important;
				}
			}

			&.area-chart-legend-checkbox-Concealed {
				.mat-checkbox-background {
					background-color: #fd760d !important;
				}
			}

			&.area-chart-legend-checkbox-Allowed {
				.mat-checkbox-background {
					background-color: #a2b2d2 !important;
				}
			}

			&.area-chart-legend-checkbox-Success {
				.mat-checkbox-background {
					background-color: #a2b2d2 !important;
				}
			}

			&.area-chart-legend-checkbox-Failed {
				.mat-checkbox-background {
					background-color: #d00300 !important;
				}
			}

			&.line-chart-legend-checkbox-Blocked {
				.mat-checkbox-background {
					background-color: #d00300 !important;
				}
			}

			&.line-chart-legend-checkbox-Default {
				.mat-checkbox-background {
					background-color: #0e3277 !important;
				}
			}

			&.line-chart-legend-checkbox-Light {
				.mat-checkbox-background {
					background-color: #3d80fc !important;
				}
			}
		}

		&.mat-mdc-checkbox-checked {
			&.line-chart-legend-checkbox-Blocked {
				.mdc-checkbox__background {
					background-color: #d00300 !important;
				}
			}

			&.line-chart-legend-checkbox-Default {
				.mdc-checkbox__background {
					background-color: #0e3277 !important;
				}
			}
		}
	}

	.processing-overview__top-file-types--black {
		justify-self: center;

		& .mat-divider-vertical {
			border-right-color: #485161;
		}
	}

	.processing-overview__top-file-types .mat-divider.mat-divider-vertical {
		border-right-width: 2px;
	}
}

mat-tooltip-component {
	.custom-tooltip-group-filter {
		margin-right: 40px !important;
	}

	.custom-tooltip-instance-filter {
		margin-right: 40px !important;
	}

	.config-version-tooltip.mat-tooltip {
		max-width: 350px;
	}
}

app-media-firewall-overview {
	.processing-overview__verdicts {
		.apexcharts-tooltip {
			transform: translateX(45%);
		}
	}

	.apexcharts-legend {
		justify-content: center !important;
	}
}

app-combo-chart {
	mat-checkbox {
		&.mat-checkbox-checked {
			&.average-session-chart-legend-checkbox-AverageFiles {
				.mat-checkbox-background {
					background-color: #a4a8ae !important;
				}
			}

			&.average-session-chart-legend-checkbox-AverageTime {
				.mat-checkbox-background {
					background-color: #1b273c !important;
				}
			}

			&.media-chart-legend-checkbox-Media {
				.mat-checkbox-background {
					background-color: #1b273c !important;
				}
			}

			&.media-chart-legend-checkbox-MediaWithThreats {
				.mat-checkbox-background {
					background-color: #d00300 !important;
				}
			}

			&.file-chart-legend-checkbox-Sanitized {
				.mat-checkbox-background {
					background-color: #008a00 !important;
				}
			}

			&.file-chart-legend-checkbox-Threats {
				.mat-checkbox-background {
					background-color: #d00300 !important;
				}
			}

			&.file-chart-legend-checkbox-Files {
				.mat-checkbox-background {
					background-color: #1b273c !important;
				}
			}
		}
	}
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex {
	display: flex;
}

app-my-opswat-license,
app-products-license,
app-kiosk-l-series-license {
	mat-form-field.mat-form-field {
		&-appearance-outline {
			.mat-form-field-wrapper {
				.mat-form-field-subscript-wrapper {
					.mat-error {
						max-width: 400px;
					}
				}
			}
		}
	}
}

app-inventory-overview {
	.security-table {
		.mat-cell {
			padding-top: 0px !important;
			padding-bottom: 0px !important;
		}
	}

	table.mat-table .example-detail-row td.mat-cell {
		border-bottom: none !important;
	}

	table.mat-table tr.mat-row:not(.selected):hover {
		background-color: transparent;
	}
}

app-my-opswat-license,
app-products-license,
app-kiosk-l-series-license,
app-security {
	.mat-form-field-infix {
		display: flex !important;
		align-items: center !important;
		// justify-content: center !important;
		height: 40px !important;
	}

	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		display: flex !important;
		align-items: center !important;
	}

	.is-clickable {
		.mdc-text-field--disabled {
			pointer-events: visible;
		}
	}
}

.calendar .md-drppicker {
	display: flex;
	flex-wrap: wrap;
	padding: 20px 15px;
	justify-content: end;
	font-family: Roboto, Arial, sans-serif !important;

	.prev,
	.next {
		visibility: hidden;
	}

	.off {
		text-decoration: none;
	}
}

.md-drppicker.ltr .calendar.right {
	display: none;
}

.calendar {
	.md-drppicker .dropdowns {
		width: 70px !important;
	}

	.md-drppicker thead th {
		font-weight: 500 !important;
		color: #3d4a68;
	}

	.md-drppicker td:not(.off) {
		opacity: 1 !important;
	}

	.md-drppicker .calendar-time .select .select-item {
		font-size: 14px !important;
	}

	.md-drppicker .buttons {
		margin-top: 20px !important;

		.btn {
			margin-left: 10px;
			color: white;
			background-color: #2672fb;
			border-radius: 0 !important;
			box-shadow: none !important;
			font-size: 13px !important;
			text-transform: none !important;
			padding: 0 12px !important;
		}

		.btn:hover {
			color: white;
			background-color: #0059c3;
		}

		.btn-default {
			background-color: transparent !important;
			border-radius: 0 !important;
			box-shadow: none !important;
			color: #3d4a68;
		}

		.btn-default:hover {
			color: #0059c3;
			background-color: white;
		}
	}

	.md-drppicker td.active,
	.md-drppicker td.active:hover {
		background-color: #2672fb !important;
		border-color: transparent;
		color: #fff;
	}

	.md-drppicker td.active {
		color: #fff !important;
		border-radius: revert !important;
	}
}

app-core-processing-history-detail {
	.off-screen {
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	ul {
		margin-top: 0px !important;
	}

	button.btn[disabled] {
		app-icon-up {
			polyline {
				stroke: #fff;
			}
		}

		app-icon-down {
			polyline {
				stroke: #fff;
			}
		}
	}

	.general-chart {
		margin-left: 30px;

		.row-item {
			display: flex;
			align-items: flex-start;
			margin-bottom: 5px;
			line-height: 12px;
			height: 10px;

			>span {
				white-space: nowrap;
				font-weight: 500;
			}

			app-line-bar {
				margin-right: 10px;
				margin-bottom: 0px;

				.wrapper-line-bar-step {
					height: 10px;
				}

				.wrapper-line-bar-step .line {
					height: 10px;
				}

				.wrapper-line-bar-step .bar .line {
					height: 10px;
					background-color: #cb0704 !important;
				}
			}
		}

		position: relative;
		min-height: 50px;
		height: 64px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.mat-accordion {
		// background-color: transparent;
		background-color: #b7c3dc;
		border: none !important;
	}

	.mat-content.mat-content-hide-toggle {
		margin-right: 0px;
	}

	.mat-expansion-panel {
		// background-color: transparent;
		background-color: #b7c3dc;
		border: none !important;
		// padding-left: 4px !important;
		width: 115px;
		padding: 4px 0px 4px 2px !important;
	}

	.mat-expansion-panel-header-title {
		margin-right: 0px;
	}

	.mat-expansion-panel .mat-expansion-panel-body {
		padding: 5px 0px !important;
	}

	.mat-expansion-panel-header {
		height: 17px;
		width: 115px;
		background-color: #b7c3dc;
		border: none !important;
		padding: 5px 2px;
	}

	.mat-expansion-panel-header.mat-expanded {
		height: 17px;
	}

	.mat-content {
		color: #3d4a68;
		font-size: 11px;
		background-color: #b7c3dc;
	}

	.identical-hash {
		font-size: 12px;
	}
}

app-reports-notifications {
	.instance-button {
		.mdc-button__label {
			width: 100%;
		}
	}
}

// grid-stack
.grid-stack {
	.row {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-left: -15px;
		box-sizing: border-box;
	}

	.col-md-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
		box-sizing: border-box;
	}

	.col-md-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
		box-sizing: border-box;
	}

	.col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		box-sizing: border-box;
	}

	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		position: relative;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		box-sizing: border-box;
	}

	.dashboard-content-container.bg-grey .grid-stack-item-content {
		background-color: #f9fafb;
	}
}

.font-normal {
	font-size: 14px;
}

.font-large {
	font-size: 16px;
}

// for ng2-tooltip
.hide-tooltip {
	display: none !important;
}

.ng2-tooltip.tooltip::after {
	display: none !important;
}

.wrap-anywhere {
	overflow-wrap: anywhere;
}

.text-wrap {
	text-wrap: wrap;
}

mat-dialog-container>* {
	display: grid;
	height: 100%;
	grid-template-rows: auto 1fr auto;
}

.bpOpen {
	width: 100%;
}

@media (min-width: 768px) {
	.bpOpen {
		width: 400px;
	}
}

@media (min-width: 1024px) {
	.bpOpen {
		width: max(400px, 30%) !important;
		max-width: 500px !important;
	}
}

app-result-confirmation {
	display: grid;
	height: 100%;
	grid-template-rows: auto 320px auto;
}

app-last-seen,
app-version,
app-health-status {
	.title {
		font-size: 14px;
		font-weight: 500;
		line-height: 16.41px;
		text-align: left;
		color: #43526b;
	}

	.sub-title {
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: left;
		color: #43526b;
	}

	.count-title {
		font-size: 12px;
		font-weight: 400;
		line-height: 17px;
		text-align: left;
		min-width: 100px;
		color: #3d4a68;
	}

	.status-box {
		display: flex;
		align-items: center;
		width: 100%;
	}
}

.word-break-word {
	word-break: break-word;
}