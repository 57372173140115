//
// We have no footer for now
mat-sidenav-container mat-sidenav-content main.has-footer {
	padding-bottom: 40px !important;
}

.mat-checkbox .mat-checkbox-label {
	font-size: 14px;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
	opacity: 0.8 !important;
	background-color: #1b273c;

	&.dark-backdrop-custom {
		opacity: 0.75 !important;
		background-color: #fff;
	}

	&.cdk-overlay-transparent-backdrop {
		background-color: transparent;
		opacity: 0 !important;
	}
}

// move it to opswatui display cell give row extra space
.mat-cell {
	padding-top: 5px !important;
	padding-bottom: 5px !important;
}

// move it to opswatui
.mat-option-disabled {
	cursor: not-allowed;
	color: #a2b2d2 !important;
}

// move it to opswatui
.mat-tab-label,
.mat-tab-link {
	font-size: 15px !important;
}

.mat-form-field-appearance-outline {
	.mat-form-field-flex {
		margin-top: 0 !important;
	}
}

.mat-paginator-container {
	margin-bottom: 110px;
	align-items: baseline !important;
}

.mat-form-field-type-mat-select .mat-form-field-label {
	top: 2.4em !important;
}

.mat-tab-body-content {
	overflow: hidden !important;
}

.mat-ghost .mat-button-wrapper {
	display: flex;
	align-items: center;
}

table.mat-table .setting-group .mat-button.mat-primary:hover {
	color: white !important;
}

.mat-menu-panel {
	border-radius: 0 !important;
}

.mat-option[aria-disabled='true'] {
	pointer-events: none;
}

.mat-form-field.mat-with-error {
	.mat-form-field-subscript-wrapper {
		margin-top: 0 !important;
		padding: 0 !important;

		.mat-error {
			padding: 0 !important;
		}
	}
}

.mat-mdc-form-field.mat-with-error {
	margin: 5px 0px;

	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		min-height: 28px !important;
		padding-top: 5px !important;
		padding-bottom: 0px !important;
		height: 28px;
	}

	.mat-mdc-form-field-subscript-wrapper {
		display: none;
	}

	&.has-error {
		.mat-mdc-form-field-subscript-wrapper {
			display: block;
		}
	}
}

// move it to opswatui
.flex-shrink-none {
	flex-shrink: 0;
}

// fix table row not vertical aligned
.mat-icon {
	&__m-b {
		margin-bottom: 4px !important;
	}
}

.custom-padding-overlay-dialog .mat-dialog-container {
	padding: 0;
}

.mat-radio-button {
	&.is-large {
		.mat-radio-outer-circle {
			height: 20px;
			width: 20px;
		}

		.mat-radio-container {
			height: 20px;
			width: 20px;
		}

		.mat-radio-inner-circle {
			left: 4px;
			top: 4px;
		}

		.mat-radio-label {
			display: flex;
			flex-direction: column;

			.mat-radio-label-content {
				padding: 3px 0 0;
			}
		}

		&.mat-radio-checked {
			.mat-radio-inner-circle {
				transform: scale(0.7);
				left: 4px;
				top: 4px;
			}
		}
	}
}
