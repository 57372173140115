app-header {
	width: 100%;
	display: block;
	position: sticky;
	z-index: 2;
	top: 0;
	background-color: white;

	.new-notification span.mat-button-wrapper::after {
		width: 30% !important;
		height: 30%;
		position: absolute;
		border-radius: 100%;
		content: '';
		left: 60%;
		top: 10%;
	}

	header {
		align-items: center;
		height: 90px;
		justify-content: flex-end;
		flex-direction: row;

		display: flex;
		padding: 20px 0;
		margin: 0 auto;

		ul {
			@media (min-width: 800px) {
				margin: 0;
			}
			@media (max-width: 800px) {
				margin: 10px 0 20px 0;
			}

			padding: 0;
			list-style-type: none;

			li {
				display: inline-block;

				a {
					text-decoration: none;
					position: relative;

					&.has-icon {
						padding-left: 26px;
					}

					.mat-icon {
						width: 20px;
						height: 20px;
						font-size: 20px;
						margin-right: 5px;
						position: absolute;
						left: 0;
						top: -2px;
					}
				}
			}
		}

		.max-w-80 {
			max-width: 80px;
		}

		.account-btn {
			font-size: 14px;
		}
	}

	.title-confirmation {
		font-size: 12px;
	}
	.help-item:hover {
		mat-icon > svg > path{
			stroke: #2672fb;
		}
	}
}

.help-content {
	padding: 0 !important;
	margin: 0 !important;
	a {
		text-decoration: none;
		color: #3d4a68;
	}

	ul,
	li {
		list-style: none;
	}

	.help-panel {
		width: 220px;
	}

	.text-sm {
		font-size: 13px;
		color: #3d4a68;
	}

	.help-item {
		height: 36px;
		padding: 8px 16px;
	}

	.help-item:hover {
		background-color: rgb(228, 233, 245);
	}

	.m-r-16 {
		margin-right: 16px;
	}

	.icon-sm {
		height: 18px !important;
		width: 18px !important;
	}
}

.spinner {
	left: 40px;
}

.w-fit-content {
	width: fit-content;
}


.switch-account {
	&--list {
		max-height: 200px;
		overflow: auto;
	}

	&--item {
		min-height: 25px;
		align-items: center;
	}
}

button.account-btn.mat-button ::ng-deep {
	.mat-button-wrapper {
		display: flex;
	}
}

.menu-list {
	list-style: none;
}

mat-progress-spinner {
	width: 20px !important;
	height: 20px !important;

	&::ng-deep {
		svg {
			width: 20px !important;
			height: 20px !important;
		}
	}
}

mat-icon.clear-noti {
	display: contents;
}