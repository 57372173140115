@import '@angular/material/theming';

@mixin custom-tooltip-theme($theme) {
	$primary: $mat-palette-primary;
	$shades: $mat-palette-shades;

	.tooltip-container {
		font-size: 14px !important;
		line-height: 1.3;
		padding: 10px 15px !important;
		overflow-wrap: break-word;
		word-wrap: break-word;
		border-radius: $border-radius !important;
		background-color: $primary-white !important;
		color: $primary-black !important;
		box-shadow: 0 0 15px mat-color($palette-opswat-shades, 400, 0.4);
		&--top {
			::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 15px;
				bottom: -7px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 1000;
			  }
		}
		&--right {
			::before {
				content: "";
				display: block;
				position: absolute;
				width: 15px;
				height: 100%;
				left: -7px;
				z-index: 1000;
				top: 0;			
			  }
		}
		&--bottom {
			::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 15px;
				top: -7px;
				left: 50%;
				transform: translateX(-50%);
				z-index: 1000;
			  }
		}
		&--left {
			::before {
				content: "";
				display: block;
				position: absolute;
				width: 15px;
				height: 100%;
				right: -7px;
				z-index: 1000;
				top: 0;	
			  }
		}
	}

}
