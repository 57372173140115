@use '@angular/material' as mat;

@mixin page-title-m2($theme) {
	.page-title {
		$shades: $mat-palette-shades;

		.slash {
			color: mat-color($shades, 50);
		}
		.item-betwwen {
			border-bottom: black;
			color: mat-color($shades, 50) !important;
			&:hover {
				text-decoration: none !important;
				color: mat-color($shades, 500) !important;
			}
		}
	}
}

.page-title {
	&--breadcrumb,
	&--tab {
		display: flex;
		align-items: center;

		&-right {
			position: absolute;
			right: 0;
		}
	}

	&--breadcrumb {
		margin-bottom: 20px;
	}

	&--title {
		font-size: 16px;
		line-height: 30px;
		&.is-last {
			font-weight: 500;
		}
		&.is-first {
			font-weight: 500;
		}
	}
	&--icon {
		vertical-align: sub;
	}
	.slash {
		font-size: 16px;
	}
	.icon-wrap {
		width: 30px !important;
		display: flex;
		.mat-icon {
			margin: 0 auto;
			width: 30px !important;
		}
	}
}

.edit-mode {
	padding: 16px;
}

.bg-grey {
	background-color: #f4f4f5;
}
