@import '@angular/material/theming';

@mixin mat-dialog-component-theme-m2($theme) {
	.mat-mdc-dialog-surface {
		padding: 24px;
		border-radius: 0px !important;
	}

	.dialog-padding-none {
		.mat-mdc-dialog-surface {
			padding: 0px !important;
		}
	}

	.mat-mdc-dialog-content {
		--mdc-dialog-supporting-text-color: #3d4a68 !important;

		.description {
			color: #707ea4;
		}
	}
}