//Color Primary
.color-primary-50 {
	color: mat-color($palette-opswat-primary, 50);
}

.color-primary-100 {
	color: mat-color($palette-opswat-primary, 100);
}

.color-primary-200 {
	color: mat-color($palette-opswat-primary, 200);
}

.color-primary-300 {
	color: mat-color($palette-opswat-primary, 300);
}

.color-primary {
	color: $primary-blue;
}

.color-primary-500 {
	color: mat-color($palette-opswat-primary, 500) !important;
}

.color-primary-600 {
	color: mat-color($palette-opswat-primary, 600);
}

.color-primary-700 {
	color: mat-color($palette-opswat-primary, 700);
}

.color-primary-800 {
	color: mat-color($palette-opswat-primary, 800);
}

.color-primary-900 {
	color: mat-color($palette-opswat-primary, 50);
}

.color-primary-A700 {
	color: mat-color($palette-opswat-primary, A700);
}

// Background Primary
.bg-primary-50 {
	background-color: mat-color($palette-opswat-primary, 50);
}

.bg-primary-100 {
	background-color: mat-color($palette-opswat-primary, 100);
}

.bg-primary-200 {
	background-color: mat-color($palette-opswat-primary, 200) !important;
}

.bg-primary-300 {
	background-color: mat-color($palette-opswat-primary, 300);
}

.bg-primary {
	background-color: $primary-blue;
}

.bg-primary-500 {
	background-color: mat-color($palette-opswat-primary, 500);
}

.bg-primary-600 {
	background-color: mat-color($palette-opswat-primary, 600);
}

.bg-primary-700 {
	background-color: mat-color($palette-opswat-primary, 700);
}

.bg-primary-800 {
	background-color: mat-color($palette-opswat-primary, 800);
}

.bg-primary-900 {
	background-color: mat-color($palette-opswat-primary, 50);
}

.bg-primary-A700 {
	background-color: mat-color($palette-opswat-primary, A700);
}

// Color Light Neutrals
.color-neutrals-50 {
	color: mat-color($palette-opswat-shades, 50) !important;
}

.color-neutrals-100 {
	color: mat-color($palette-opswat-shades, 100);
}

.color-neutrals-200 {
	color: mat-color($palette-opswat-shades, 200);
}

.color-neutrals-300 {
	color: mat-color($palette-opswat-shades, 300);
}

.color-neutrals-400 {
	color: mat-color($palette-opswat-shades, 400);
}

.color-neutrals {
	color: mat-color($palette-opswat-shades, 500);
}

.color-neutrals-A100 {
	color: mat-color($palette-opswat-shades, A100);
}

.color-neutrals-A200 {
	color: mat-color($palette-opswat-shades, A200);
}

.color-neutrals-A400 {
	color: mat-color($palette-opswat-shades, A400);
}

.color-neutrals-A700 {
	color: mat-color($palette-opswat-shades, A700);
}

// Background ligth neutrals
.bg-neutrals-50 {
	background-color: mat-color($palette-opswat-shades, 50);
}

.bg-neutrals-100 {
	background-color: mat-color($palette-opswat-shades, 100);
}

.bg-neutrals-200 {
	background-color: mat-color($palette-opswat-shades, 200);
}

.bg-neutrals-300 {
	background-color: mat-color($palette-opswat-shades, 300);
}

.bg-neutrals-400 {
	background-color: mat-color($palette-opswat-shades, 400);
}

.bg-neutrals {
	background-color: mat-color($palette-opswat-shades, 500);
}

.bg-neutrals-A100 {
	background-color: mat-color($palette-opswat-shades, A100);
}

.bg-neutrals-A200 {
	background-color: mat-color($palette-opswat-shades, A200);
}

.bg-neutrals-A400 {
	background-color: mat-color($palette-opswat-shades, A400);
}

.bg-neutrals-A700 {
	background-color: mat-color($palette-opswat-shades, A700);
}

.bg-neutrals-A700-30-p {
	background-color: mat-color($palette-opswat-shades, A700, 30%);
}

// Color Warn
.color-warn {
	color: $tertiary-warn;

	circle {
		// work arround to change mat-icon color
		fill: $tertiary-warn;
	}
}

.color-warn-700 {
	color: mat-color($palette-opswat-warn, 700);
}

.color-warn-A700 {
	color: mat-color($palette-opswat-warn, A700);
}

// Background Color Warn
.bg-warn {
	background-color: $tertiary-warn;
}

.bg-warn-700 {
	background-color: mat-color($palette-opswat-warn, 700);
}

.bg-warn-A700 {
	background-color: mat-color($palette-opswat-warn, A700);
}

// Color success
.color-success {
	color: $tertiary-success;

	circle {
		// work arround to change mat-icon color
		fill: $tertiary-success;
	}
}

.color-success-700 {
	color: mat-color($palette-opswat-success, 700);
}

.color-success-A700 {
	color: mat-color($palette-opswat-success, A700);
}

// Background Color success
.bg-success {
	background-color: $tertiary-success;
}

.bg-success-700 {
	background-color: mat-color($palette-opswat-success, 700);
}

.bg-success-A700 {
	background-color: mat-color($palette-opswat-success, A700);
}

// Color highlight
.color-highlight {
	color: $tertiary-highlight !important;

	circle {
		// work arround to change mat-icon color
		fill: $tertiary-highlight;
	}
}

.color-highlight-A700 {
	color: mat-color($palette-opswat-highlight, A700);
}

// Background Color highlight
.bg-highlight {
	background-color: $tertiary-highlight;
}

.bg-highlight-A700 {
	background-color: mat-color($palette-opswat-highlight, A700);
}

.bg-highlight-500 {
	background-color: mat-color($palette-opswat-highlight, 500);
}

.color-primary-400 {
	color: mat-color($palette-opswat-primary, 400);
}

// Color Info
.color-info {
	color: $tertiary-info;
}

// Background Color Info
.bg-info {
	background-color: $tertiary-info;
}

// Color White
.color-white {
	color: $primary-white;
}

// Background Color White
.bg-white {
	background-color: $primary-white !important;
}

.bg-color-dark {
	background-color: $primary-dark;
}

.health {
	&-operational {
		color: #008a00;
	}

	&-minor {
		color: #fdbd0d;
	}

	&-critical {
		color: #d00300;
	}

	&-unknown {
		color: #bcbfc3;
	}

	&-significant {
		color: #ed6706;
	}

	&-blocker {
		color: #990200;
	}
}

.color {
	&-over-90 {
		color: #008a00 !important;

		path {
			stroke: #008a00 !important; // work around
		}
	}

	&-from-31-to-90 {
		color: #fdbd0d;
	}

	&-from-1-to-30 {
		color: #ed6706;
	}

	&-expired {
		color: #d00300;
	}

	&-no-license {
		color: #bcbfc3;
	}
}

.icon-24 {
	font-size: 24px !important;
	width: 24px !important;
	height: 24px !important;
}
.bg-color-tag {
	background-color: #e9ecf2;
	&:hover {
		background-color: #dee4f0;
	}
}

.color-menubg-600 {
	color: mat-color($palette-opswat-menubg, 600);
}

.color-menubg-200 {
	color: mat-color($palette-opswat-menubg, 200);
}

.color-menubg-A200 {
	color: mat-color($palette-opswat-menubg, A200);
}
