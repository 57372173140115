@import '@angular/material/theming';

@mixin mat-form-field-component-theme-m2($theme) {
	$warn: $mat-palette-warn;
	$success: $mat-palette-success;
	$foreground: $foreground-theme-default;
	$custom: $custom-colors-theme-default;
	$shades: $mat-palette-shades;

	mat-form-field.mat-form-field-appearance-outline.without-border .mat-form-field-outline {
		color: #b7c3dc;
		background: white !important;
	}

	.mat-mdc-form-field-infix {
		.mat-icon {
			height: 20px;
			width: 24px;
		}

		.mat-mdc-select-arrow-wrapper {
			height: 20px;
		}
	}

	.mat-mdc-form-field-hint-wrapper,
	.mat-mdc-form-field-error-wrapper {
		padding: 0px !important;
	}

	.mat-form-field-outline-none {
		.mdc-line-ripple {
			display: none !important;
		}

		&.mat-select-error-none {
			.mat-mdc-form-field-subscript-wrapper {
				display: none;
			}

			&.mat-mdc-form-field {
				height: 38px;

				.mdc-text-field--filled:not(.mdc-text-field--disabled) {
					--mdc-filled-text-field-container-color: transparent;
				}

				&.mat-focused .mat-mdc-form-field-focus-overlay {
					opacity: 0;
				}

				--mat-select-placeholder-text-color: --mdc-text-button-label-text-color;
			}

			.mat-mdc-select-arrow {
				color: var(--mdc-text-button-label-text-color);

				& svg {
					font-size: 20px;
					width: 20px;
					height: 20px;
				}
			}
		}

		.mdc-text-field--filled.mdc-text-field--disabled {
			background-color: transparent;
		}

		.mat-mdc-select-placeholder {
			font-weight: 500 !important;
		}

		&.mat-focused:not(.mat-form-field-disabled) {
			.mat-mdc-select-placeholder {
				--mat-select-placeholder-text-color: #2672fb;
			}
		}

		&:hover:not(.mat-form-field-disabled) {
			.mat-mdc-text-field-wrapper {
				--mat-select-placeholder-text-color: #2672fb !important;
			}

			.mat-mdc-select-arrow {
				color: #2672fb;
			}
		}
	}

	.mat-select-outline-border-bottom {
		.mdc-line-ripple {
			display: none !important;
		}

		&.mat-select-error-none {
			.mat-mdc-form-field-subscript-wrapper {
				display: none;
			}

			&.mat-mdc-form-field {
				height: 34px;

				.mdc-text-field--filled:not(.mdc-text-field--disabled) {
					--mdc-filled-text-field-container-color: transparent;
				}

				&.mat-focused .mat-mdc-form-field-focus-overlay {
					opacity: 0;
				}
			}
		}

		.mat-mdc-select-placeholder {
			font-weight: 500 !important;
		}

		.mat-mdc-text-field-wrapper {
			border-bottom: 1px solid #b7c3dc;
			padding-left: 0px;
		}

		&.mat-focused {
			.mat-mdc-text-field-wrapper {
				border-bottom: 2px solid #2672fb;
			}
		}

		&.search:not(.mat-mdc-form-field-disabled):not(.mat-focused):hover {
			.mat-mdc-text-field-wrapper {
				border-bottom: 1px solid #707ea4;
			}
		}

		&.edit-form.mat-select-error-none.mat-mdc-form-field {
			height: $sm-height;

			.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
				padding-top: 5px;
			}
		}
	}

	.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
		opacity: 0 !important;
	}

	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
		padding-top: 10px !important;
		padding-bottom: 10px !important;
		min-height: $element-height !important;
	}

	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
	.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
		--mdc-outlined-text-field-hover-outline-color: #707ea4;
		--mdc-outlined-text-field-outline-color: #b7c3dc;
	}

	.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
	.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
	.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
		--mdc-outlined-text-field-disabled-outline-color: #d3d9e6;
	}

	.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined.mdc-text-field--no-label.mdc-text-field--disabled {
		background-color: #f9fafb;
	}

	.mdc-notched-outline__leading,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__trailing {
		border-radius: $border-radius !important;
	}

	.mat-mdc-form-field-error-wrapper {
		padding: 0 !important;
	}

	.mat-mdc-form-field-error {
		max-width: none !important;
	}

	.mdc-text-field__input::-webkit-calendar-picker-indicator {
		display: block !important;
		cursor: pointer;
	}

	app-import-export {
		.add-new-button {
			.mdc-text-field--no-label {
				background-color: #1d6bfc !important;
				width: 140px;
				border-radius: 0px;
			}

			.mdc-text-field--disabled.mdc-text-field--no-label {
				background-color: #d3d9e6 !important;
				color: #f9fafb !important;
			}

			.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
				padding-top: 5px !important;
				padding-bottom: 5px !important;
				min-height: $sm-height !important;
			}

			.mat-mdc-select-arrow,
			.mat-mdc-select-placeholder {
				color: #fff !important;
			}
		}
	}

	app-account {
		.mat-mdc-form-field-error {
			min-width: 150px;
		}
	}

	app-local-editor,
	app-sso-editor,
	app-active-directory-editor,
	app-active-directory {
		.mat-mdc-form-field-hint {
			--mat-sidenav-content-text-color: #58668799;
			color: var(--mat-sidenav-content-text-color);
		}

		input {
			--mdc-outlined-text-field-input-text-placeholder-color: #707ea4;
		}

		--mat-select-placeholder-text-color: #707ea4;
	}

	app-add-new-user-and-group {
		.role-selected {
			--mdc-filled-text-field-container-color: white;

			.mat-mdc-form-field-focus-overlay {
				background-color: white;
			}

			.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
				opacity: 0;
			}

			.mdc-line-ripple {
				display: none;
			}

			&.p-none>.mat-mdc-text-field-wrapper {
				padding: 0 !important;
			}
		}
	}

	app-update-group-dialog {
		--mdc-outlined-text-field-input-text-color: #3D4A68;
	}
}