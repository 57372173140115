@import '@angular/material/theming';

@mixin mat-paginator-component-theme-m2($theme) {
	mat-paginator {
		&.mat-mdc-paginator {
			background: none;

			.mat-mdc-paginator-page-size-label {
				display: none;
			}

			.mat-mdc-paginator-page-size {
				margin-right: 0;
			}

			.mat-mdc-paginator-range-label {
				margin: 0 6px 0 10px !important;
			}

			.mat-mdc-paginator-icon {
				font-size: 20px !important;
				width: 28px !important;
				height: 28px !important;
			}

			.mat-mdc-icon-button {
				padding: 0;
			}

			.mat-mdc-select-arrow {
				width: 20px;
				height: 20px;
				font-size: 20px;
			}

			.mdc-notched-outline {
				display: none;
			}
		}

		&.with-pagination {
			.mat-form-field {
				.mat-form-field-wrapper {
					padding-bottom: 1em !important;
				}
			}
		}
	}
}
