@import '@angular/material/theming';

@mixin mat-checkbox-component-theme-m2($theme) {
	$primary: $mat-palette-primary;
	$warn: $mat-palette-warn;
	$success: $mat-palette-success;
	$foreground: $foreground-theme-default;
	$custom: $custom-colors-theme-default;
	$shades: $mat-palette-shades;

	// .mat-mdc-checkbox {
	// 	height: 20px !important;
	// }

	.mdc-checkbox {
		padding: 0 10px 10px 0 !important;
	}

	.mdc-checkbox__background,
	.mdc-checkbox__ripple {
		border-radius: $border-radius !important;
	}

	.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark,
	.mdc-checkbox .mdc-checkbox__native-control:disabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
		width: 12px;
		height: 12px;
		top: 1px;
		left: 1px;
	}

	.mdc-checkbox .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
		border: 1px solid #a2b2d2 !important;
	}

	.mdc-checkbox
		.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
		~ .mdc-checkbox__background {
		border: 1px solid #a2b2d2 !important;
	}

	.mdc-checkbox
		.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
		~ .mdc-checkbox__background {
		border: 1px solid #a2b2d2 !important;
	}

	.mdc-checkbox__background {
		border: 1px solid transparent !important;
		width: 16px !important;
		height: 16px !important;
	}

	.mat-mdc-checkbox-ripple,
	.mat-mdc-checkbox .mdc-checkbox__ripple {
		display: none;
	}
}
