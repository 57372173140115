@import '@angular/material/theming';

@mixin mat-option-component-theme-m2($theme) {
	.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
		background-color: transparent !important;
	}

	.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
		background: #e9ecf2 !important;
	}
	.mat-mdc-option {
		min-height: 42px !important;
	}
}
