@import '@angular/material/theming';

@mixin clone-search-field-component-theme($theme) {
	$shades: $mat-palette-shades;
	$primary: $mat-palette-primary;
	app-search-field {
		.search-wrap {
			.search-icon {
				&:hover {
					.mat-icon {
						color: mat-color($primary);
					}
				}
			}
		}
	}
}
