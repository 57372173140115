@import '@angular/material/theming';

@mixin mat-tooltip-component-theme-m2($theme) {
	.mdc-tooltip__surface {
		font-size: 14px !important;
		line-height: 1.3;
		padding: 10px 15px !important;
		overflow-wrap: break-word;
		word-wrap: break-word;
		border-radius: $border-radius !important;
		background-color: $primary-white !important;
		color: $primary-black !important;
		box-shadow: 0 0 15px mat-color($palette-opswat-shades, 400, 0.4);
	}

	.config-version-tooltip {
		.mdc-tooltip__surface {
			max-width: 350px;
		}
	}
}