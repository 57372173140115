@import '@angular/material/theming';

@mixin mat-select-component-theme-m2($theme) {
    .mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
        --mat-option-focus-state-layer-color: transparent !important;
    }

    .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
        background-color: #e9ecf2 !important;
    }

    .mat-mdc-select-panel {
        padding: 0px !important;
    }
}
