@import '@angular/material/theming';

@mixin mat-icon-component-theme-m2($theme) {
	// svg:not(.mat-mdc-checkbox svg) {
	// 	width: 100% !important;
	// 	height: 100% !important;
	// }
	.custom-color-icon {
		path {
			stroke: currentColor;
		}
	}
}
