@mixin app-core-group($theme) {
	$primary: $mat-palette-primary;
	$success: $mat-palette-success;

	app-core-group {
		.mat-mdc-button.rename-group-selected {
			color: mat-color($primary, 500);
		}

		.input-wrapper {
			position: relative;
		}

		.default-label {
			background-color: mat-color($success, 700);
			color: $primary-white;

			border-radius: 0px;
			text-align: center;
			padding: 0 5px;
			font-size: 11px;
			font-weight: 500;
			line-height: 20px;
			height: 18px;
			// &--input {
			// 	margin-top: 5px;
			// }
		}

		.circle-check {
			color: mat-color($success);
		}

		.unwanted {
			color: $tertiary-highlight;
		}
	}
}
