@import '@angular/material/theming';

@mixin clone-deleting-group-dialog-theme($theme) {
	$shades: $mat-palette-shades;
	$success: $mat-palette-success;
	app-deleting-group-dialog {
		.groups-table {
			.row:nth-child(2n + 1) {
				background-color: rgba(mat-color($shades, 50), 0.2);
			}
		}

		.default-label {
			background-color: mat-color($success, 700);
			color: $primary-white;
			border-radius: 0px;
			text-align: center;
			padding: 0 5px;
			font-size: 11px;
			font-weight: 500;
			line-height: 20px;
			height: 18px;
		}
		
	}
}
