@import '@angular/material/theming';

@mixin mat-menu-component-theme-m2($theme) {
	$primary: $mat-palette-primary;

	.mat-mdc-menu-panel {
		max-width: none !important;
	}

	.min-width-custom {
		min-width: 600px !important;
		width: 600px !important;
		max-width: 600px !important;
	}

	.mat-mdc-menu-content {
		padding: 0px !important;
	}

	app-product-instance {
		.mat-mdc-menu-item {
			--mat-menu-item-label-text-color: $primary !important;
		}
	}
}