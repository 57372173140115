@use '@angular/material' as mat;
@use 'sass:map';
@import '@angular/material/theming';

// @opswat/ui styles
@import '../../node_modules/@opswat/ui/styles/_core';
@import '../../node_modules/@opswat/ui/styles/_theme';

// import modules
@import './mixins/app-components.theme.scss';
@import './base';
@import './color-class.scss';
//
@import './modules/page-title.scss';
@import './material/_core.scss';
@import './material/_theme.scss';
@import './mat-custom';
@import './variables';

// @import 'src/assets/scss/_layout.scss';
// Default theme
@import '../../node_modules/@opswat/ui/styles/themes/default-theme.palette';

@import '../app/views/content-view/layout/footer/footer.component.scss';
@import '../app/views/content-view/layout/header/header.component.scss';

@import 'app/views/content-view/layout/footer/footer.component.theme.scss';
@import 'app/views/content-view/layout/header/header.component.theme.scss';
@import 'app/views/content-view/layout/header/my-opswat/my-opswat.component.theme.scss';

@import '../../node_modules/@opswat/ui/styles/base/navigation-main.component.theme.scss';
@import 'gridstack/dist/gridstack.min.css';
@import 'customs/mdc-button.theme.scss';

@include mat.core();
@include mat.legacy_core();
$ocm-theme: mat.define-light-theme(
	(
		color: (
			primary: $mat-palette-primary,
			accent: $mat-palette-shades,
		),
		typography: $typography-config,
	)
);

$ocm-theme: map.set($ocm-theme, color, foreground, $foreground-theme-default);

$ocm-theme: map.set($ocm-theme, color, background, $background-theme-default);

@media (max-width: 1299px) {
	@include mat.typography-hierarchy($typography-config-smaller);
}

@media (min-width: 1300px) {
	@include mat.typography-hierarchy($typography-config);
}

@include mat.all-legacy-component-themes($ocm-theme);

@include opswat-material-theme($ocm-theme);
@include opswat-custom-components-theme($ocm-theme);
@include opswat-other-theme($ocm-theme);

@include app-components-theme($ocm-theme);
@include base-component-theme($ocm-theme);
@include html-component-theme-wrapped($ocm-theme);

@include my-opswat-component-theme($ocm-theme);
@include footer-component-theme($ocm-theme);
@include header-component-theme($ocm-theme);

// M2 theme
$mat-m2-accent2: mat.define-palette(mat.$green-palette, A200, A100, A400);
$mat-m2-primary: mat.define-palette($palette-opswat-primary, 400);
$mat-m2-accent: mat.define-palette($palette-opswat-primary, 400);
$mat-m2-ghost: mat.define-palette($palette-opswat-shades);
$mat-m2-warn: mat.define-palette($palette-opswat-warn);

$ocm-m2-theme: mat.define-light-theme(
	(
		color: (
			primary: $mat-m2-primary,
			accent: $mat-m2-primary,
			warn: $mat-m2-warn,
		),
		typography: $typography-config,
		density: -3,
	)
);

@include material-3-theme($ocm-m2-theme);
@include page-title-m2($ocm-m2-theme);
// $foreground-theme-default: (
// 	base: black,
// 	divider: indianred,
// 	dividers: indianred,
// 	disabled: indianred,
// 	disabled-button: indianred,
// 	disabled-text: indianred,
// 	elevation: indianred,
// 	hint-text: indianred,
// 	secondary-text: yellow,
// 	icon: yellow,
// 	icons: yellow,
// 	text: #3d4a68,
// 	slider-min: indianred,
// 	slider-off: indianred,
// 	slider-off-active: indianred,
// );
// $background-theme-default: (
// 	status-bar: black,
// 	app-bar: map.get($grey-palette, 900),
// 	background: #303030,
// 	hover: rgba(white, 0.04),
// 	// TODO(kara): check style with Material Design UX
// 	card: map.get($grey-palette, 800),
// 	dialog: map.get($grey-palette, 800),
// 	disabled-button: rgba(white, 0.12),
// 	raised-button: map.get($grey-palette, 800),
// 	focused-button: $light-focused,
// 	selected-button: map.get($grey-palette, 900),
// 	selected-disabled-button: map.get($grey-palette, 800),
// 	disabled-button-toggle: black,
// 	unselected-chip: map.get($grey-palette, 700),
// 	disabled-list-option: rgba(white, 0.12),
// 	tooltip: black,
// );
// $ocm-m2-theme: map.set($ocm-m2-theme, color, foreground, $foreground-theme-default);
// $ocm-m2-theme: map.set($ocm-m2-theme, color, background, $background-theme-default);
@include mat.all-component-themes($ocm-m2-theme);
@include mat.all-component-colors($ocm-m2-theme);

// form field
@include mat.form-field-theme($ocm-m2-theme);

// button
@include mat.button-density(0);
@include mat.radio-density(-3);

// slide toggle
$mat-m2-slide-toggle-primary: mat.define-palette($palette-opswat-primary, 400);
$ocm-m2-slide-toggle-theme: mat.define-light-theme(
	(
		color: (
			accent: $mat-m2-slide-toggle-primary,
			primary: $mat-m2-slide-toggle-primary,
		),
	)
);
@include mat.slide-toggle-theme($ocm-m2-slide-toggle-theme);

// border
.mdc-label {
	font-weight: 400 !important;
	font-size: 14px;
}

.mat-slide-toggle-content {
	font-size: 14px;
}

//custom css for
app-directory,
app-users,
app-search-field,
app-sso,
app-integrations,
app-generate-secret-key-dialog,
app-secret-key-detail-dialog,
app-update-secret-key-dialog,
app-certificates,
app-update-user-dialog,
app-delete-user-dialog,
app-added-directory-success-dialog,
app-delete-directory,
app-sso-editor,
app-role ,
app-pin-validation-dialog,
app-delete-roles-dialog,
app-duplicate-role-dialog,
app-toggle-role-dialog,
app-terms-and-policy,
app-reset-pin,
app-setup-pin {
	.mat-mdc-button-touch-target {
		display: none;
	}

	// .mat-mdc-icon-button.mat-mdc-button-base {
	// 	width: auto;
	// 	height: auto;
	// 	--mdc-icon-button-state-layer-size: auto;
	// }

	// svg:not(.mat-mdc-checkbox svg) {
	// 	width: 100% !important;
	// 	height: 100% !important;
	// }

	// .mat-mdc-button {
	// 	&.cancel-button,
	// 	&.discard-btn {
	// 		min-width: auto !important;
	// 		padding: 0 !important;

	// 		.mat-mdc-focus-indicator,
	// 		.mat-mdc-button-touch-target,
	// 		.mat-mdc-button-ripple,
	// 		.mdc-button__ripple {
	// 			display: none;
	// 		}
	// 	}

	// 	.mat-mdc-button-persistent-ripple {
	// 		display: none;
	// 	}
	// }

	// .mat-mdc-form-field-error-wrapper {
	// 	padding: 0 !important;
	// }

	// .mat-mdc-form-field-error {
	// 	max-width: none !important;
	// }
}

app-ui-components,
app-sample-dialog,
app-reset-password,
app-login,
app-error{
	@include mdc-button-component-theme($ocm-theme);
}
